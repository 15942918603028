import React from 'react';
import SEO from '../common/SEO';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/Footer';
import HeaderOne from '../common/header/Header';
import { CalendlyForm } from '../component/contact/CalendlyForm';

const Contact = () => {
    return (
        <>
            <SEO title="Book A Session" />
            {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section section-padding gray-container">
                    <CalendlyForm />
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.svg"} alt="Bubble" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.svg"} alt="Bubble" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                    </ul>
                </div>
            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;