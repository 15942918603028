import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h3 className="title">Empower Your Business with Tailored Software Solutions</h3>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/book-a-session"} className="axil-btn btn-fill-primary">Book a session</Link>
                    </div>
                </div>
                <div className="banner-social">
                <   div className="border-line" />
                    <ul className="list-unstyled social-icon">
                        <li><a href="https://facebook.com/linearlabs"><FaFacebookF /> Facebook</a></li>
                        <li><a href="https://twitter.com/linearlabs"><FaTwitter /> twitter</a></li>
                        <li><a href="https://www.linkedin.com/company/linearlabs"><FaLinkedinIn /> Linkedin</a></li>
                    </ul>
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default Banner;