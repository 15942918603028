import { useEffect } from 'react';

export function CalendlyForm() {
    useEffect(() => {
        const link = document.createElement('link');
        link.href = "https://assets.calendly.com/assets/external/widget.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return <div className="calendly-inline-widget" data-url="https://calendly.com/linearlabs?primary_color=219ebc" style={{ minWidth: '320px', height: '800px' }}></div>;
}