import React from 'react';

const Data = [
    {
        image: "/images/brand/zoku.svg"
    },
    {
        image: "/images/brand/reprecint.svg"
    },
    {
        image: "/images/brand/dealroom_logo.png"
    },
    {
        image: "/images/brand/cuttpay.svg"
    },
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="offset-lg-0 col-lg-3 offset-1 col-4" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" style={{ height: 42 }} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;